import React, {useState} from 'react';
import logo from './logo.svg';
import './App.css';
import {Grid, Paper, Typography, makeStyles} from '@material-ui/core';
import Accordion from './Accordion';
import Card from './Card';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles(() => ({
  subtitle: {
    marginBottom: '10px'
  },
  link: {
    color: 'white',
    cursor: 'pointer',
    lineHeight: '40px'
  },
  github: {
    width: '40px',
    height: '40px',
    marginRight: '20px'
  },
  card: {
    background: 'white',
    textAlign: 'left' as 'left',
    border: 0,
    boxShadow: 'none'
  },
  cardHeader: {
    height: '40px',
    lineHeight: '40px',
    paddingRight: '40px',
    position: 'relative' as 'relative',
    background: '#535353',
    color: 'white',
    borderRadius: 16,
    boxShadow: '0 5px 12px 0 rgba(0, 0, 0, 0.04)',
    cursor: 'pointer'
  },
  cardBody: {
    background: '#efefef',
    color: '#333',
    padding: '20px 40px',
    borderRadius: 16,
    border: '0 !important',
    boxShadow: '0 5px 12px 0 rgba(0, 0, 0, 0.04)',
  },
  expandIcon: {
    position: 'absolute' as 'absolute',
    right: 10,
    top: 16,
  },
  description: {
    borderLeft: '2px solid #d9d9d9',
    paddingLeft: '16px'
  }
}));

const App = () => {
  const content = [
    {
      header: 'Реферат по астрономии',
      description: 'Тема: «Экваториальный узел: методология и особенности»\n' +
        'Популяционный индекс выбирает зенит. Млечный Путь недоступно выслеживает центральный восход . ' +
        'Земная группа формировалась ближе к Солнцу, однако природа гамма-всплексов доступна. Огpомная пылевая кома ' +
        'дает непреложный математический горизонт – у таких объектов рукава столь фрагментарны и обрывочны, что их ' +
        'уже нельзя назвать спиральными. Скоpость кометы в пеpигелии возможна.',
    },
    {
      header: 'Реферат по химии',
      description: 'Тема: «Электроотрицательность как скорость детонации»\n' +
        'Температура абразивна. Индуцированное соответствие катализирует синтез. Раньше ученые полагали, ' +
        'что ректификация стационарно катализирует краситель.',
    },
    {
      header: 'Реферат по физике',
      description: 'Тема: «Внутримолекулярный резонатор — актуальная национальная задача»\n' +
        'Галактика, в отличие от классического случая, искажает межядерный сверхпроводник. ' +
        'Интерпретация всех изложенных ниже наблюдений предполагает, что еще до начала измерений течение ' +
        'среды усиливает фронт. Неоднородность стохастично отражает гравитационный лазер. Химическое соединение ' +
        'кумулятивно. Неоднородность эксперментально верифицируема. Мишень возбуждает нестационарный кристалл. ' +
        'Квантовое состояние синфазно отталкивает квантовый солитон даже в случае сильных локальных возмущений ' +
        'среды. Неустойчивость, как известно, быстро разивается, если вещество мгновенно восстанавливает ' +
        'гидродинамический удар как при нагреве, так и при охлаждении. Вещество волнообразно. Взрыв усиливает ' +
        'межядерный резонатор.',
    },
    {
      header: 'Реферат по музыковедению',
      description: 'Тема: «Плотностная компонентная форма как аллегро»\n' +
        'Кластерное вибрато изящно иллюстрирует неизменный динамический эллипсис. Трехчастная фактурная форма ' +
        'синхронно иллюстрирует лирический доминантсептаккорд. Живая сессия, в том числе, изящно дает фузз. ' +
        'Панладовая система изящно выстраивает хамбакер. Хорус имитирует винил, что отчасти объясняет такое количество ' +
        'кавер-версий. Процессуальное изменение, на первый взгляд, диссонирует микрохроматический интервал. ' +
        'Пентатоника сонорна. Ретро варьирует музыкальный звукоряд. Очевидно, что глиссандо регрессийно имитирует ' +
        'композиционный звукосниматель. Иными словами, глиссандо образует целотоновый нонаккорд. Лайн-ап ' +
        'многопланово диссонирует мелодический доминантсептаккорд. Соинтервалие вызывает форшлаг. Говорят ' +
        'также о фактуре, типичной для тех или иных жанров ("фактура походного марша", "фактура вальса" и пр.), ' +
        'и здесь мы видим, что явление культурологического порядка дает хамбакер. Арпеджио варьирует ' +
        'громкостнoй прогрессийный период.',
    },
  ];

  const [selected, setSelected] = useState(Math.floor(Math.random() * content.length));
  const [selectedRestyle, setSelectedRestyle] = useState(Math.floor(Math.random() * content.length));
  const classes = useStyles();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
          <a
            href="https://github.com/eugenelabonarsky/accordion"
            className={classes.link}
            target="_blank"
          >
            <GitHubIcon className={classes.github}/>
            src/components/Accordion
          </a>
        </p>
      </header>
      <section>
        <Grid container>
          <Grid item xs={12} spacing={3}>
            <Paper className="App-section">
              <Typography variant="h5" className={classes.subtitle}>Example <i>uncontrolled</i> component:</Typography>
              <Accordion>
                {content.map(({header, description}, index) => (
                  <Card key={index}>
                    <Accordion.Toggle eventKey={index} element={Card.Header}>
                      {header}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={index} element={Card.Body}>
                      {description}
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={12} spacing={3}>
            <Paper className="App-section">
              <Typography variant="h5" className={classes.subtitle}>Example <i>controlled</i> component:</Typography>
              <Accordion onToggle={setSelected} selected={selected}>
                {content.map(({header, description}, index) => (
                  <Card key={index}>
                    <Accordion.Toggle element={Card.Header} eventKey={index}>
                      {header}
                    </Accordion.Toggle>
                    <Accordion.Collapse element={Card.Body} eventKey={index}>
                      {description}
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Paper>
          </Grid>
          <Grid item xs={12} spacing={3}>
            <Paper className="App-section">
              <Typography variant="h5" className={classes.subtitle}>
                Example <i>restyled, controlled</i> component:
              </Typography>
              <Accordion onToggle={setSelectedRestyle} selected={selectedRestyle}>
                {content.map(({header, description}, index) => (
                  <Card key={index} className={classes.card}>
                    <Accordion.Toggle element={Card.Header} eventKey={index} className={classes.cardHeader}>
                      {header}
                      {index === selectedRestyle ?
                        <ExpandLessIcon className={classes.expandIcon}/> :
                        <ExpandMoreIcon className={classes.expandIcon}/>}
                    </Accordion.Toggle>
                    <Accordion.Collapse element={Card.Body} eventKey={index} className={classes.cardBody}>
                      <div className={classes.description}>
                        {description}
                      </div>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Paper>
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default App;
