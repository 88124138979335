import React, { useMemo, useState, useEffect, useCallback } from 'react';
import AccordionContext from './AccordionContext';

import Collapse from './Collapse';
import Toggle from './Toggle';
import Container from './Container';

interface IProps {
 element?: React.FunctionComponent<any>,
 selected?: number | null,
 onToggle?: (eventKey: number) => void,
 children: React.ReactNode,
 onClick?: () => void,
}

const Accordion = ({
  element: Component = Container,
  selected,
  onToggle,
  children,
  ...otherProps
}: IProps) => {
  const [eventKey, setEventKey] = useState(selected);

  useEffect(() => {
    setEventKey(selected);
  }, [selected, setEventKey]);

  const handleToggle = useCallback(
    eventKey => {
      if (selected !== undefined && onToggle) {
        onToggle(eventKey);
        return;
      }
      setEventKey(eventKey);
    },
    [selected, onToggle, setEventKey]
  );

  const context = useMemo(() => {
    return {
      selected: eventKey,
      onToggle: handleToggle
    };
  }, [eventKey, handleToggle]);

  return (
    <AccordionContext.Provider value={context}>
      <Container {...otherProps}>{children}</Container>
    </AccordionContext.Provider>
  );
};


Accordion.Toggle = Toggle;
Accordion.Collapse = Collapse;

export default Accordion;
