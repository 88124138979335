import React from 'react';
import CardHeader from './CardHeader';
import CardBody from './CardBody';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    border: '1px solid #ddd',
    boxShadow: '0 5px 12px 0 rgba(0, 0, 0, 0.04)',
    '&:not(:last-child)': {
      borderBottom: 'none',
    }
  },
}));

const Card = ({ children, className }: IProps) => {
  const classes = useStyles();
  return <div className={classnames(className, classes.root)}>{children}</div>;
};

Card.Header = CardHeader;
Card.Body = CardBody;

export default Card;
