import React from 'react';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
  },
}));

const CardHeader = ({ children, className, ...props}: IProps) => {
  const classes = useStyles();
  return (
    <div className={classnames(className, classes.root)} {...props}>
      {children}
    </div>
  );
};

export default CardHeader;
