import React from 'react';

interface IProps {
  children: React.ReactNode,
  className?: string,
}

const Container = ({children, className, ...props}: IProps) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default Container;