import React from 'react';
import {useAccordionContext} from '../hooks';
import {Collapse as CollapseTransition} from '@material-ui/core';

interface IProps {
 element: React.FunctionComponent<any>,
 eventKey: number | null,
 children: React.ReactNode,
 className?: string,
}

const Collapse = ({
  element: Component,
  eventKey,
  children,
  ...otherProps
}: IProps) => {
  const { selected } = useAccordionContext();

  return (
    <CollapseTransition in={selected === eventKey}>
      <Component {...otherProps}>{children}</Component>
    </CollapseTransition>
  );
};

export default Collapse;
