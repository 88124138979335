import React from 'react';
import {useAccordionContext} from '../hooks';

const useAccordionClick = (eventKey: any, onClick: any) => {
  const {onToggle, selected} = useAccordionContext();
  return (event: any) => {
    onToggle(eventKey === selected ? null : eventKey);
    if (onClick) {
      onClick(event);
    }
  };
};

interface IProps {
 element: React.FunctionComponent<any>,
 eventKey: number | null,
 children: React.ReactNode,
 onClick?: () => void,
 className?: string,
}

const Toggle = ({
  element: Component,
  eventKey,
  onClick,
  children,
  ...otherProps
}: IProps) => {
  const accordionClick = useAccordionClick(eventKey, onClick);

  return (
    <Component onClick={accordionClick} {...otherProps}>
      {children}
    </Component>
  );
};

export default Toggle;
